<template>
  <app-list-view
    server-side
    show-group-by
    app="audit"
    model="masterposition"
    api-url="audit/master-position/"
    :title="$t('menu.masterPosition')"
    :createTo="{ name: 'masterPositionCreate', query: $route.query }"
    :editTo="{ name: 'masterPositionEdit' }"
    :headers="headers"
    :query="$route.query"
  />
</template>

<script>
import AppListView from '@components/AppListView'

export default {
  name: 'masterPositionList',
  components: {
    AppListView
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('fields.masterPosition'),
          value: 'name',
          groupable: false
        },
        { text: this.$t('fields.state'), value: 'state', groupable: false },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          groupable: false,
          hideFilter: true
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'masterPosition'
    })
  }
}
</script>
